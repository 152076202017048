@use 'sats-ui-lib/tokens/spacing';
@use 'sats-ui-lib/tokens/light';
@use 'sats-ui-lib/tokens/corner-radius';
@use 'sass:math';

.create-case {
  $breakpoint: 950px; // NOTE: Must be the same breakpoint as layout
  max-width: 900px;

  &__title {
    margin-bottom: spacing.$l;
  }

  &__buttons {
    position: fixed;
    bottom: 0;
    background-color: light.$surface-primary-default;
    width: 100%;
    padding: spacing.$m 0;
    z-index: 10;
    text-align: center;

    @media (min-width: $breakpoint) {
      left: 250px;
      padding-left: spacing.$xxl;
      text-align: left;
    }

    > * + * {
      margin-left: spacing.$xs;
    }
  }

  &__member-search {
    margin-bottom: spacing.$s;
  }

  &__member-checkbox {
    &:not(:last-child) {
      margin-bottom: spacing.$l;
    }
  }

  &__member-info {
    padding: spacing.$l;
    background-color: light.$surface-primary-default;
    border-radius: corner-radius.$s;
  }

  &__member-card {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: spacing.$m;
    border-radius: corner-radius.$s;
    border: 1px solid light.$ge-border-default;
  }

  &__member-card-info {
    display: flex;
    flex-direction: column;
    gap: spacing.$xxs;
  }

  &__member-card-item {
    display: flex;
    align-items: center;
    gap: spacing.$xs;
    color: light.$on-surface-primary-alternate;
  }

  &__personal-info {
    display: flex;
    flex-wrap: wrap;
    gap: spacing.$s;

    > * {
      flex: 0 0 100%;
      @media (min-width: $breakpoint) {
        flex: 0 0 calc(50% - math.div(spacing.$s, 2));
      }
    }
  }

  &__case-info {
    display: flex;
    flex-wrap: wrap;
    gap: spacing.$xl;
    margin-top: spacing.$l;
    padding: spacing.$l;
    background-color: light.$surface-primary-default;
    border-radius: corner-radius.$s;

    > * {
      flex: 0 0 100%;

      @media (min-width: $breakpoint) {
        flex: 0 0 calc(50% - math.div(spacing.$xl, 2));
      }
    }
  }

  &__text-area {
    @media (min-width: $breakpoint) {
      flex: 0 0 100%;
    }
  }

  &__attachment-wrapper {
    display: flex;
    flex-direction: column;
    gap: spacing.$s;
  }

  &__attachment-label {
    color: light.$on-buttons-on-link-default;
    cursor: pointer;
  }

  &__attachment-placeholder {
    color: light.$on-buttons-on-secondary-disabled;
  }

  &__no-attachment {
    border-radius: corner-radius.$s;
    background: light.$surface-secondary-default;
    padding: spacing.$s;
    display: flex;
    flex-direction: column;
    gap: spacing.$xs;
  }

  &__no-attachment-description {
    color: light.$on-surface-secondary-alternate;
  }

  &__no-attachment-header {
    display: flex;
    gap: spacing.$xxs;
    align-items: center;
  }

  &__categories {
    padding: spacing.$l;
    display: flex;
    justify-content: space-between;
    border-radius: corner-radius.$s;
    background: light.$surface-primary-default;
    margin-bottom: spacing.$l;
    align-items: center;

    @media (min-width: $breakpoint) {
      flex: 0 0 calc(50% - math.div(spacing.$l, 2));
    }
  }

  &__category-wrapper {
    display: flex;
    flex-wrap: wrap;
    row-gap: spacing.$m;
    column-gap: spacing.$xl;
  }
}
